import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box,
  Button
} from 'rebass';
import styles from './Servicios.module.css';

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Servicios = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Nosotros",

	 },
	 en: {
		title: "Nosotros",

	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [ beenHere, setBeenHere] = useState(false)


	return(
			<div className={styles.Container} id="Nosotros">
				<TrackVisibility partialVisibility>		
					{({ isVisible }) => {
						if(isVisible)
							setBeenHere(true)
						return(
						<React.Fragment>
							<div  className={"MaxDimensions"} style={{position:"relative"}}>
								<Box sx={{
									backgroundImage: `url(${props.cover})`,
									height: "215px",
									width: "100vw",
									position: "relative",
									left: "50%",
									right: "50%",
									marginLeft: "-50vw",
									marginRight: "-50vw",
									backgroundRepeat: "no-repeat",
									backgroundSize: "cover"
									}} mb={[50,90,90]}></Box>
								<Flex sx={{position:"absolute", top: "70px"}} justifyContent={["flex-start","space-between","space-between"]} alignItems="center" >
									<Text
									fontWeight="700"
									// sx={{textShadow:"#00000052 0 9px 6px"}} 
									// textAlign="left"
									fontSize={[45, 50, 50]} 
									color="white">{props.title}</Text>
								</Flex>
							</div>
						</React.Fragment>
					)}}	
				</TrackVisibility>
			</div>
	);
}

export default Servicios;

