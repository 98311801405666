import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box,
  Button
} from 'rebass';
import styles from './Cover.module.css';


import downArrow from "../../../assets/downArrow.svg" 
import {isMobile} from 'react-device-detect'; 
import cover1 from "../../../assets/sections/cover/cover1.png"
import cover2 from "../../../assets/sections/cover/coverbg.jpg"
import Carousel from "../../../components/Carousel/Carousel"

import { motion } from "framer-motion"

const Cover = (props) => {

	// useEffect(()=>{
	// 	if(isMobile && window.innerWidth < 600){
	// 	    var heights = window.innerHeight;
	// 		console.log("mobile",heights);
	// 	    document.getElementById("CoverMainPage").style.height = heights + "px";
	// 	}
	// },[])

	const windowWidth = window.innerWidth;
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title1: "Brindamos",
		title2: "Soluciones",
		title3: "Contables",
		subtitle: "Ayudando a incrementar la liquidez y rentabilidad de las empresas de nuestros clientes.",
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	return(
			<Box className={styles.Container} id="CoverMainPage" sx={{backgroundImage:[`url(${cover2})`,`url(${cover2})`,"none"],backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center"}}  pt={[0]}>
				<div className={"MaxDimensions"} style={{maxWidth:"1440px",position:"relative",justifyContent:"center"}} >
					{/* <Image className="getArrowWide" onClick={props.scrollTo.Nosotros} src={downArrow} sx={{position:"absolute",bottom:40,left:"50%",transform:"translate(-50%,0)",cursor:"pointer",width:30}}/> */}
						<motion.div style={{height:"100%",display:"flex",flex:1,alignItems:"center"}} initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0}} transition={{ duration: 1.2}}>
							<Flex flexDirection={["column","column", "row"]} height="100%" flex="1"  >
								<Flex className={styles.CopyContainer} flex="1" flexDirection="column" justifyContent="center" pt={["0","20px","80px"]} pl={["15px", "50px", 0]} pr={["15px","50px",60]}  height="fit-content" width={["100%","100%","60%"]}>
									<Flex  flexDirection="column" justifyContent="flex-start" alignItems="flex-start" >
										<Text
											fontWeight="400"
											className="MadeSonara" 
											textAlign="left"
											color={["white","white","#223644"]}
											// sx={{textShadow:"#00000052 0 9px 6px"}} 
											fontSize={[40, 50, 62]}
											lineHeight= {["50px","50px","94px"]}
											sx={{textShadow:["none","0px 3px 6px #00000029"]}}
											>{trans.title1}</Text>
										<Text
											fontWeight="400"
											className="MadeSonara" 
											textAlign="left"
											color={["white","white","#223644"]}
											// sx={{textShadow:"#00000052 0 9px 6px"}}
											sx={{textShadow:["none","0px 3px 6px #00000029"]}} 
											fontSize={[55, 80, 90]} 
											
											><span className={styles.italicUnderline}>{trans.title2}</span></Text>
										<Text
											fontWeight="400"
											className="MadeSonara" 
											textAlign="left"
											color={["white","white","#223644"]}
											// sx={{textShadow:"#00000052 0 9px 6px"}} 
											sx={{textShadow:["none","0px 3px 6px #00000029"]}}
											fontSize={[55, 80, 90]} 
											><span className={styles.italicUnderline}>{trans.title3}</span></Text>
									</Flex>
									<Flex  minHeight={[67, 46]} justifyContent="flex-start" alignItems="center" marginTop="28px">
										<Text 
											lineHeight={["25px"]}
											letterSpacing="0.8px" 
											textAlign="left" 
											color={["white","white","#223644"]}
											// sx={{textShadow:"#00000052 0 9px 6px"}}
											width={["345px","440px","440px"]}
											fontSize={[16,18,18]}>{trans.subtitle}</Text>
									</Flex>
									<Flex  minHeight={[66, 160]} justifyContent="flex-start" alignItems={["flex-start","center"]} marginTop={["51px","0px","0px"]}>
										<Button 
											onClick={props.scrollTo.Servicios}
											padding="23px 35px"
											sx={{color:"#C9B069",fontWeight:"700", border: "1px solid #C9B069", background: "transparent",borderRadius: 0, cursor: "pointer"}}
											fontSize={[16,16,18]}>VER SERVICIOS</Button>
									</Flex>
								</Flex>
								<Flex className="DeskOnly" flexDirection="column" height="100%" width={["100%","100%","60%"]}>
										<Flex>
											<Image width="100%" src={cover1}/>
										</Flex>
								</Flex>
							</Flex>
						</motion.div>
				</div>
			</Box>
	);
}

export default Cover;