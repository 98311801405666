import React from 'react';

import sideBarLines from '../../../../assets/menuIcon.svg'
import styles from './DrawerToggle.module.css';

const drawerToggle = (props) => (
	<div className={styles.DrawerToggle} onClick={props.clicked}>
		
		<img className={props.atTop ? "" : styles.Image2} src={sideBarLines} alt=""/>
	</div>
);

export default drawerToggle;