import React from 'react';

import styles from './Footer.module.css';




const Footer = (props) => {

	return(
		<div className={styles.Container} id="Footer">
			<div className={styles.Bottom}>
				<div className={styles.BottomText}>© 2020 TGO	<span className={styles.MobileOnly}>.</span></div>
				<div className={styles.verticalLine}>|</div>
				<div className={styles.BottomText}>Design by <span 
					onClick={() => {window.open('https://www.truo.mx/', '_blank')}} style={{fontWeight:"bold",cursor:"pointer"}}>Truo.</span></div>
			</div>
		</div>
	);
}

export default Footer;