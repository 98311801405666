import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import Carousel from "../../../components/Carousel/Carousel"
import {
	Flex,
	Text,
	Image,
	Box
} from 'rebass';
import send from '../../../assets/sendArrow.svg'
import styles from './Clientes.module.css';
import cliente from "../../../assets/cliente.svg"
import arrowRight from "../../../assets/arrowRight.svg"
import arrowLeft from "../../../assets/arrowLeft.svg"
import ratingStar from '../../../assets/svg/rating-star.svg'
import dotSlider from '../../../assets/svg/dotSlider.svg'

import watercolor from "../../../assets/sections/reviews/watercolor.png"
import reviewsBox from "../../../assets/svg/reviewsBox.svg"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Clientes = (props) => {
	const history = useHistory();
	const CarouselRef = useRef();
	let trans = new LocalizedStrings({
		es: {
			title: "Clientes",
			subtitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
			seeMore: "Ver más"
		}
	});

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		arrows: false,
		dotsClass: `slick-dots ${styles.customDots}`,
		slidesToScroll: 3,
		customPaging: i => (
			<label className={styles.dotSlider} alt="dotSlider"></label>
		),
		responsive: [
			{
				breakpoint: 1120,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	const next = () => {
		CarouselRef.current.slickNext();
	}
	const previous = () => {
		CarouselRef.current.slickPrev();
	}

	const clients = [
		{
			img: cliente,
			client: "Nombre cliente1",
			person: "Alejandra Ávalos",
			desc: "El mejor lugar para llevar tus procesos contables, excelente atención, profesionalismo y servicio. Ampliamente recomendable por la experiencia y conocimientos que tienen en diferentes áreas.",
			rating: 5
		},
		{
			img: cliente,
			client: "Nombre cliente2",
			person: "Anahi Varela",
			desc: "Excelente atención, muy buen trato, rápidos y efectivos, siempre al pendiente de sus clientes. Muy Recomendado.",
			rating: 5
		},
		{
			img: cliente,
			client: "Nombre cliente3",
			person: "Guille Roldán Martínez",
			desc: "Demuestra experiencia en su despacho. Destaco su compromiso, el trato de su personal, la rapidez y efectividad con la que resuelven mis asuntos. Ampliamente recomendable.",
			rating: 5
		}
		,
		{
			img: cliente,
			client: "Nombre cliente3",
			person: "Yecenia Gomez",
			desc: "Gracias por resolver mis dudas y aclararlas además de sentirme cómoda, el contador es excelente explicando.",
			rating: 5
		}
		,
		{
			img: cliente,
			client: "Nombre cliente2",
			person: "Carlos Alvarez",
			desc: "Buena atención y profesionalismo se recomienda para aquellos que necesiten asesoría o algún servicio",
			rating: 5
		},
		,
		{
			img: cliente,
			client: "Nombre cliente2",
			person: "David Salazar Garcia",
			desc: "Muy profesional, y todo a tiempo. Te explica y te resuelve cualquier inquietud.",
			rating: 5
		},
	]

	const bgImageStyles = {
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	}

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [beenHere, setBeenHere] = useState(false);

	const generateStarsRating = (nstars) => {
		return Array.from({ length: nstars }, (item, index) =>
			<img key={index} src={ratingStar} />
		)
	}

	return (
		<div className={styles.Container} id="Clientes" style={{
			backgroundImage: `url(${watercolor})`,
			backgroundSize: "cover",
			position: "relative",
			backgroundAttachment: "fixed",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat"
		}}>
			<TrackVisibility partialVisibility>
				{({ isVisible }) => {
					if (isVisible)
						// setBeenHere(true)
						return (
							<motion.div initial={{ opacity: 0, y: 300 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, y: (isVisible || beenHere) ? 0 : 300 }} transition={{ duration: 1 }} className={"MaxDimensions"} style={{ position: "relative" }}>

								<Flex width="100%"

									// sx={{
									// backgroundImage: `url(${ClientesBg})`,
									// backgroundSize:"cover",
									// position:"relative",
									// backgroundAttachment: "fixed",
									// backgroundPosition: "center",
									// backgroundRepeat: "no-repeat"}} 
									flexDirection="column"
									height="510px" px={"19px"} mt={["60px"]} pt={["60px"]}>
									<Text
										marginBottom={["83px", "40px", "70px"]}
										marginLeft={["10px", 0, 0]}
										fontWeight="100"
										textAlign={["left", "center"]}
										fontFamily="MadeSonara"
										fontSize={[45, 45, 50]}
										letterSpacing={["5.4px", "5.4px", "6px"]}
										lineHeight={["54px", "59px", "59px"]}
										className={styles.titleOpinion}
										color="#2E333E">NUESTROS CLIENTES OPINAN</Text>
									{/* <Image src={arrowRight} onClick={() => next()} className={styles.AbsoluteArrow+" getBig" } sx={{right:["5px",100,150]}}/>
									<Image src={arrowLeft} onClick={() => previous()} className={styles.AbsoluteArrow+" getBig" } sx={{left:["5px",100,150]}}/> */}
									<Slider ref={CarouselRef} {...settings} className="carousel">

										{
											clients.map((cli, i) => (
												<Flex key={"flex-" + i} display="flex !important" className={styles.Grab} sx={{ maxHeight: "402px" }}>
													<Box width={["100%"]} key={"box1" + i}>
														<Box className={styles.opinionBox} p="30px 30px 70px" sx={{
															backgroundImage: `url(${reviewsBox})`,
															backgroundSize: "contain",
															backgroundPosition: "center",
															position: "relative",
															backgroundRepeat: "no-repeat",
															minHeight:"300px",
															display: "flex",
														}}>
															<Box key={"box3" + i} mt="10px" maxWidth={240} margin="auto">
																<Text className={styles.textOpinion} key={"text3" + i} color="#223644" fontSize={[16]} lineHeight="28px" >
																	{cli.desc}
																</Text>
															</Box>
														</Box>
														<Box>
															<div className={styles.ratingContainer}>
																<span className={styles.ratingTitle}>{cli.person}</span>
																<span className={styles.ratingSubtitle}>Google Reviews</span>
																<div className={styles.ratingStarsContainer}>
																	{generateStarsRating(cli.rating)}
																</div>
															</div>
														</Box>
													</Box>

												</Flex>
											))
										}


									</Slider>
								</Flex>
							</motion.div>
						)
				}}
			</TrackVisibility>
		</div>
	);
}

export default Clientes;