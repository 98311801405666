import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
	Flex,
	Text,
	Image,
	Box,
	Button
} from 'rebass';
import styles from './Nosotros1.module.css';
import nosotros from "../../../assets/sections/services/services5.png"

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Servicios = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
		es: {
			title: "Nosotros",

		},
		en: {
			title: "Nosotros",

		}
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [beenHere, setBeenHere] = useState(false)


	return (
		<div className={styles.Container} id="Nosotros">
			<TrackVisibility partialVisibility>
				{({ isVisible }) => {
					if (isVisible)
						setBeenHere(true)
					return (
						<React.Fragment>
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000 }} transition={{ duration: 1 }} className={"MaxDimensions"} style={{ position: "relative", maxWidth: "1200px !important" }}>
								<Flex className={styles.containerWeCover} paddingLeft={[15,0,0]} paddingRight={[15,0,0]}  flexDirection={["column", "column", "column"]} justifyContent={["flex-start"]} alignItems={["flex-start"]} mb={[50, 50, 90]} 
										>
									<Flex  width={["100%", "100%", "100%"]} justifyContent={["flex-start"]}>
										<Text
											
											width={["50%","100%","100%"]}
											fontWeight="700"
											pt={["50px","106px"]}
											sx={{ lineHeight: ["41px", "59px", "59px"], letterSpacing: ["2px"] }}
											textAlign={["left"]}
											fontSize={[35, 50, 50]}

											mb={[50, 50, 95]}
											color="#223644">¿QUIÉNES SOMOS?</Text>
									</Flex>
									<Flex width={["100%", "100%", "100%"]} justifyContent={["center", "center", "flex-start"]}>
										<Text
											fontWeight="500"
											textAlign="left"
											className="RalewayMedium"
											sx={{ lineHeight: ["35px"], letterSpacing: ["1px"] }}
											fontSize={[20, 20, 22]}
											color="#223644">Somos un despacho contable que cree en un modelo de crecimiento económico sostenible en base a las necesidades de nuestros clientes. Nuestro amplio portafolio de servicios contables, fiscales y financieros ofrece poder lograr un ecosistema saludable para las empresas.</Text>
									</Flex>
								</Flex>

							</motion.div>

						<div id="NosotrosContainer__2">
							<motion.div initial={{ opacity: 0, y: 300 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, y: (isVisible || beenHere) ? 0 : 300 }} transition={{ duration: 1 }} className={"MaxDimensions"} style={{ position: "relative", maxWidth: "1200px !important" }}>
									<Flex id="caja3" flexDirection={["column", "row", "row"]} justifyContent="space-between"
										mb={[50, 90, 90]}
										className={styles.containerSection}
									>
										<Box  id="caja1" width={["100%","50%","50%"]}
											sx={{ backgroundColor: "#F0F0F0", padding: ["81px 15px 82px 15px ","81px 15px 81px 35px","141px 134px 109px 140px"] }}
											order={[2, 0, 0]} justifyContent={["center", "center", "flex-start"]}>
											<Text
												fontWeight="100"
												maxWidth={["100%","90%","409px"]}
					
												mb={[0, 0, 0]}
												sx={{ lineHeight: "35px" }}
												textAlign="left"
												// sx={{textShadow:"#00000052 0 9px 6px"}} 
												fontSize={[18, 18, 18]}
												color="#2E333E"> Con nuestro profundo conocimiento en el sector contribuimos en capitalizar nuevas oportunidades, evaluando estrategias de administración y logrando un crecimiento responsable, creando un impacto significativo y positivo.</Text>
										</Box>
										<Box maxHeight="454px" display={"flex"} id="caja2" width={["100%","50%","50%"]}  justifyContent={["center", "center", "flex-end"]} sx={{position:"relative" }}>
											<div className={styles.overlayImg}></div>
											<img style={{ objectFit: "cover", width: "100%", maxHeight:"100%"}} src={nosotros} />
										</Box>
									</Flex>
									
									</motion.div>
							</div>

						</React.Fragment>
					)
				}}
			</TrackVisibility>
		</div>
	);
}

export default Servicios;

