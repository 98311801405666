import React, { useRef,useEffect } from 'react';

import styles from './Servicios.module.css'

import Layout from '../../components/Layout/Layout';
import Footer from '../../sections/Home/Footer/Footer';
import Title from '../../sections/Servicios/Title';
import Callapsables from '../../sections/Servicios/Callapsables';
import Contacto from '../../sections/Home/Contacto/Contacto';
import cover from '../../assets/sections/services/nomina/nomina.png';
import { Fade } from "react-awesome-reveal"
import svg1 from '../../assets/sections/services/nomina/svgnomina/Grupo 209.svg';
import svg2 from '../../assets/sections/services/nomina/svgnomina/Grupo 210.svg';
import svg3 from '../../assets/sections/services/nomina/svgnomina/Grupo 212.svg';
import svg4 from '../../assets/sections/services/nomina/svgnomina/Grupo 211.svg';
const Home = (props) => {

	const ContactoRef = useRef(null)


	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70) ,behavior: 'smooth',})
	}

	const accordion = [
		{title: "SERVICIOS DE NÓMINA", svg: svg1, content: <ul> 
			<li>Recibos de nómina electrónicos</li>
			<li>Listas de raya </li>
			<li>Cálculo PTU, aguinaldo, vacaciones, prima vacacional </li>
			<li>Premios de asistencia, premios de puntualidad, vales de despensa, caja de ahorro</li>
		</ul>},
		{title: "CONTRATOS/DOCUMENTACIÓN", svg: svg2, content: <ul> 
			<li>Contrato de trabajo de planta y temporal</li>
			<li>Reglamento interior de trabajo</li>
			<li>NOM 035</li>
			<li>Comisiones mixtas</li>
			<li>Organigrama</li>
			<li>Condiciones generales de trabajo</li>
		</ul>},
		{title: "IMPUESTOS POR SUELDOS", svg: svg3, content: <ul> 
			<li>Inscripción al impuesto sobre nóminas</li>
			<li>Cambio de domicilio, apertura sucursal del impuesto sobre nóminas</li>
			<li>Calculo impuesto sobre nóminas estatal</li>
			<li>Subsidio al empleo</li>
			<li>Determinación ISR retenido por sueldos y salarios</li>
			<li>Determinación ISR retenido por asimilados a sueldos</li>
		</ul>},
		{title: "ASIMILADOS A SUELDOS Y SALARIOS", svg: svg4, content: <ul> 
			<li>Timbrado de recibos</li>
			<li>Cálculo de asimilados a sueldos </li>
		</ul>}
	]
	
	
	return(
		<Fade delay={200}>
			<Layout scrollTo={{
							Contacto:toBottomContact

							}}>
				<div className={styles.Nosotros}>
						<div id="Nosotros1"   >
							<Title title="Nomina" cover={cover}/>
						</div>

						<div id="Nosotros2"  >
							<Callapsables title="Nomina" accordion={accordion}/>
						</div>

						<div id="Contacto" ref={ContactoRef}>
							<Contacto />
						</div>
						
						<div id="Footer">
							<Footer />
						</div>
				</div>
			</Layout>	
		</Fade>
	)	
};


export default Home;