import React, { useRef,useEffect } from 'react';

import styles from './Nosotros.module.css'

import Layout from '../../components/Layout/Layout';
import Footer from '../../sections/Home/Footer/Footer';
import Proyectos from '../../sections/Home/Proyectos/Proyectos';
import Cover from '../../sections/Home/Cover/Cover';
import Nosotros1 from '../../sections/Nosotros/Nosotros1/Nosotros1';
import Nosotros2 from '../../sections/Nosotros/Nosotros2/Nosotros2';
import Servicios from '../../sections/Home/Servicios/Servicios';
import Clientes from '../../sections/Home/Clientes/Clientes';
import Contacto from '../../sections/Home/Contacto/Contacto';

import { Fade } from "react-awesome-reveal"

const Home = (props) => {

	const ContactoRef = useRef(null)


	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70) ,behavior: 'smooth',})
	}
	
	
	return(
		<Fade delay={200}>
			<Layout scrollTo={{
							Contacto:toBottomContact

							}}>
				<div className={styles.Nosotros}>
						<div id="Nosotros1"   style={{paddingTop: 50}}>
							<Nosotros1 />
						</div>

						<div id="Nosotros2"  >
							<Nosotros2 />
						</div>

						<div id="Contacto" ref={ContactoRef}>
							<Contacto />
						</div>
						
						<div id="Footer">
							<Footer />
						</div>
				</div>
			</Layout>	
		</Fade>
	)	
};


export default Home;