import React, { useRef,useEffect } from 'react';

import styles from './Contact.module.css'

import Layout from '../../components/Layout/Layout';
import Footer from '../../sections/Home/Footer/Footer';
import Contacto from '../../sections/Home/Contacto/Contacto';
import Contact from '../../sections/Contact/Contacto/Contacto';

import { Fade } from "react-awesome-reveal"

const Home = (props) => {

	const ContactoRef = useRef(null)


	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70) ,behavior: 'smooth',})
	}
	
	
	return(
		<Fade delay={200}>
			<Layout scrollTo={{
							Contacto:toBottomContact

							}}>
				<div className={styles.Nosotros}>
						<div id="Contact"   >
							<Contact />
						</div>

						<div id="Contacto" ref={ContactoRef}>
							<Contacto />
						</div>
						
						<div id="Footer">
							<Footer />
						</div>
				</div>
			</Layout>	
		</Fade>
	)	
};


export default Home;