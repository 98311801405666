import React, { useRef,useEffect } from 'react';

import styles from './Servicios.module.css'

import Layout from '../../components/Layout/Layout';
import Footer from '../../sections/Home/Footer/Footer';
import Title from '../../sections/Servicios/Title';
import Callapsables from '../../sections/Servicios/Callapsables';
import Contacto from '../../sections/Home/Contacto/Contacto';
import cover from '../../assets/sections/services/imss/imss.png';
import { Fade } from "react-awesome-reveal"
import svg1 from '../../assets/sections/services/imss/svgimss/Trazado 151.svg';
import svg2 from '../../assets/sections/services/imss/svgimss/Grupo 140.svg';
import svg3 from '../../assets/sections/services/imss/svgimss/Trazado 158.svg';

const Home = (props) => {

	const ContactoRef = useRef(null)


	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70) ,behavior: 'smooth',})
	}

	   

	const accordion = [
		{title: "ALTAS PATRONALES", svg: svg1, content: <ul> 
			<li>Altas patronales personas físicas</li>
			<li>Altas patronales personas morales</li>
			<li>IDSE.- avisos de altas de empleados, bajas de empleados, modificación de salarios, sueldos variables del IMSS bimestrales</li>
			<li>Cambios de domicilio</li>
		</ul>},
		{title: "DECLARACIONES INFORMATIVAS", svg: svg2, content: <ul> 
			<li>Presentación anual de la prima de riesgo de trabajo</li>
			<li>Certificados digitales del IMSS personas físicas y morales</li>
			<li>Plan de seguridad social</li>
			<li>Contestación de invitaciones y requerimientos</li>
			<li>Escritorio virtual</li>
			<li>Incapacidades</li>
		</ul>},
		{title: "DETERMINACIÓN DE CUOTAS", svg: svg3, content: <ul> 
			<li>SUA.- Altas, bajas, modificación de sueldo, finiquitos, ausencias, pago de diferencias</li>
			<li>SIPARE.- Generación de archivo normal de pago, generación de archivo extemporáneo de pago</li>
			<li>Cálculo de cuotas obrero-patronales</li>
			<li>Cálculo de diferencias</li>
		</ul>}   
	]
	
	
	return(
		<Fade delay={200}>
			<Layout scrollTo={{
							Contacto:toBottomContact

							}}>
				<div className={styles.Nosotros}>
						<div id="Nosotros1"   >
							<Title title="IMSS" cover={cover}/>
						</div>

						<div id="Nosotros2"  >
							<Callapsables title="IMSS" accordion={accordion} />
						</div>

						<div id="Contacto" ref={ContactoRef}>
							<Contacto />
						</div>
						
						<div id="Footer">
							<Footer />
						</div>
				</div>
			</Layout>	
		</Fade>
	)	
};


export default Home;