import React from 'react';
import styles from './Input.module.css';

const input = (props) => {
	let inputElement = null;
	const inputClasses = [styles.InputElement];
	let validationError = null;
	let errorMessage = "";
	if (props.errorsArray) {
		props.errorsArray.map((err) => {
			if (errorMessage !== "") {
				errorMessage = errorMessage + ',' + err;
			}
			else {
				var uppercaseFirstLetter = err.charAt(1).toUpperCase() + err.slice(2);
				errorMessage = errorMessage + uppercaseFirstLetter;
			}
			return true;
		})
	}
	else {
		errorMessage = "Valor Incorrecto"
	}
	// props.errorsArray ?
	// 	props.errorsArray.map((err) => {
	// 		errorMessage = errorMessage + err;
	// 	})
	// :
	// 	null;

	if (props.invalid && props.shouldValidate && props.touched) {
		inputClasses.push(styles.Invalid);
		validationError = <p className={styles.ValidationError}>{errorMessage}</p>;
	}

	switch (props.elementType) {
		case ('input'):
			inputElement = <div className={styles.Input}>
				<input
					className={inputClasses.join(' ')}
					{...props.elementConfig} value={props.value}
					onChange={props.changed}
					ref={props.inputRef}
				/>
				{validationError}
			</div>
			break;
		case ('textarea'):
			inputElement =
				<div className={styles.Input}>
					<textarea
						className={inputClasses.join(' ')}
						{...props.elementConfig} value={props.value}
						onChange={props.changed}
						ref={props.inputRef}
					/>
					{validationError}

				</div>
			break;
		case ('select'):
			inputElement = (
				<select
					ref={props.inputRef}
					className={inputClasses.join(' ')}
					value={props.value}
					onChange={props.changed}>
					{props.elementConfig.options.map(option => (
						<option
							key={option.value}
							value={option.value}>
							{option.displayValue}
						</option>
					))}
				</select>
			);
			break;
		case ('radio'):
			inputElement = (
				<div>
					<label className={styles.customRadio}>
						<input
							type="radio"
							ref={props.inputRef}
							name={props.name}
							value={props.value}
							onChange={props.onChangeFunction}
						/>
						<div className={`${styles.radioButton} ${props.selected === props.value ? styles.radioButtonSelected : ''}`}>
							<div className={`${props.selected === props.value ? styles.filled : ''} ${styles.getFill}`}>
								{

									props.imgSvg
								}
							</div>
							<span>{props.label}</span>

						</div>

					</label>
				</div>
			)
			break;
		default:
			inputElement = <input
				className={inputClasses.join(' ')}
				{...props.elementConfig} value={props.value}
				onChange={props.changed} />;
	}


	return (
		<div className={styles.Input}>
			<label className={styles.Label} >
				{props?.shouldValidate?.required ? '* ' : null}
				{props.elementLabel}
			</label>
			{inputElement}
		</div>
	);


}


export default input;