import React, { useRef,useEffect } from 'react';

import styles from './Servicios.module.css'

import Layout from '../../components/Layout/Layout';
import Footer from '../../sections/Home/Footer/Footer';
import Title from '../../sections/Servicios/Title';
import Callapsables from '../../sections/Servicios/Callapsables';
import Contacto from '../../sections/Home/Contacto/Contacto';
import cover from '../../assets/sections/services/contabilidad/contabilidad.png';

import { Fade } from "react-awesome-reveal"
import svg1 from '../../assets/sections/services/contabilidad/svgcontabilidad/Grupo 214.svg';
import svg2 from '../../assets/sections/services/contabilidad/svgcontabilidad/Grupo 215.svg';
import svg3 from '../../assets/sections/services/contabilidad/svgcontabilidad/Grupo 216.svg';
const Home = (props) => {

	const ContactoRef = useRef(null)


	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70) ,behavior: 'smooth',})
	}

	          

	const accordion = [
		{title: "SERVICIOS DE CONTABILIDAD", svg: svg1, content: <ul> 
			<li>Asesoría fiscal-contable</li>
			<li>Contabilidad electrónica</li>
			<li>Actualización de contabilidades</li>
			<li>Pago referenciado de impuestos</li>
			<li>Devolución y compensación de impuestos</li>
			<li>Declaraciones anuales y mensuales</li>
			<li>Declaraciones informativas</li>
			<li>Balanza electrónica</li>
			<li>Precierres mensuales y anuales</li>
			<li>Estados financieros</li>
			<li>Diagnóstico fiscal (revision SAT, IMSS, INFONAVIT, Secretaría de finanzas)</li>
			<li>Creación de empresa SAS (Sociedad por Acciones Simplificada)</li>
		</ul>},
		{title: "TRÁMITES Y AVISOS SAT", svg: svg2, content: <ul> 
		<li>Buzón tributario</li>
		<li>Mi portal SAT</li>
		<li>Mi contabilidad SAT</li>
		<li>Apertura y cierre de sucursales o establecimientos</li>
		<li>Casos y aclaraciones, verificación de domicilio</li>
		<li>Cambio de domicilio, aumento/disminución de obligaciones</li>
		<li>E-firma obtención, revocación y renovación</li>
		<li>Sellos digitales</li>
		<li>Constancia de situación fiscal</li>
		<li>Opinión de cumplimiento de obligaciones fiscales (32-D)</li>
	</ul>},
	         
	{title: "PADRÓN DE IMPORTADORES", svg: svg3, content: <ul> 
	<li>Alta padrón de importadores</li>
	<li>Alta patentes</li>
	<li>Vucem</li>
	<li>Sectores específicos</li>
</ul>}
	]
	
	
	return(
		<Fade delay={200}>
			<Layout scrollTo={{
							Contacto:toBottomContact

							}}>
				<div className={styles.Nosotros}>
						<div id="Nosotros1"  >
							<Title title="Contabilidad" cover={cover}/>
						</div>

						<div id="Nosotros2"  >
							<Callapsables title="Contabilidad" accordion={accordion}/>
						</div>

						<div id="Contacto" ref={ContactoRef}>
							<Contacto />
						</div>
						
						<div id="Footer">
							<Footer />
						</div>
				</div>
			</Layout>	
		</Fade>
	)	
};


export default Home;