import React,{ useState, useEffect } from 'react';

import styles from './Layout.module.css'
import Toolbar from '../Navigation/Toolbar/Toolbar'

import SideDrawer from '../Navigation/SideDrawer/SideDrawer'



const Layout = (props) => {
	const [showSideDrawer, setShowSideDrawer] = useState(false);
	const [atTop, setAtTop] = useState(true);

	// const handleScroll = e => {
	// 	if (window.scrollY > 0) {
	// 		document.getElementById("Header").classList.add("whiteHeader");
	// 		setAtTop(false)
	// 	}
	// 	else{
	// 		document.getElementById("Header").classList.remove("whiteHeader");
	// 		setAtTop(true)
	// 	}
	// }

	useEffect(() => {
		window.scrollTo(0,0)
	},[])


	


	const sideDrawerClosedHandler = () => {
		setShowSideDrawer(false);
	}


	const sideDrawerToggledHandler = () => {
		setShowSideDrawer(!showSideDrawer);
	}


	return(
		<React.Fragment>
			
			<Toolbar  serviciosSectionRef = {props.scrollTo.Servicios} atTop={atTop} ContactRef={props.scrollTo.Contacto} NosotrosRef={props.scrollTo.Nosotros} ServiciosRef={props.scrollTo.Servicios} BlogRef={props.scrollTo.Blog} closed={sideDrawerClosedHandler} toggled={sideDrawerToggledHandler}/> 
			<SideDrawer open={showSideDrawer} closed={sideDrawerClosedHandler} serviciosSectionRef = {props.scrollTo.Servicios} ContactRef={props.scrollTo.Contacto} NosotrosRef={props.scrollTo.Nosotros} ServiciosRef={props.scrollTo.Servicios} BlogRef={props.scrollTo.Blog}/>
			<main className={styles.Content}>
				{props.children}
			</main>
		</React.Fragment>
	);
}


export default Layout;