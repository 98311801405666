import React from 'react';

import NavigationItems from '../NavigationItems/NavigationItems';
import styles from './SideDrawer.module.css';
import Backdrop from '../../UI/Backdrop/Backdrop';

import closeSideDraw from '../../../assets/close-menu.png'
import Logo from '../../Logo/Logo';


const sideDrawer = props => {
	let attachedClassed = [styles.SideDrawer,styles.Close];
	if(props.open){
		attachedClassed = [styles.SideDrawer,styles.Open];
	}
	return (
		<React.Fragment>
		<Backdrop show={props.open} clicked={props.closed}/>
		
		
		<div className={attachedClassed.join(' ')}>
			<div className={styles.sideDrawerClose}>
			    <Logo closed={props.closed} />
				<img src={closeSideDraw} alt="" onClick={props.closed} style={{width:23,height:23}} />
			</div>
			<nav style={{height:"100%"}}>
				<NavigationItems openSideDrawer={props.open} closed={props.closed} scrollTo={props.scrollTo} serviciosSectionRef = {props.serviciosSectionRef}  ContactRef={props.ContactRef} NosotrosRef={props.NosotrosRef} ServiciosRef={props.ServiciosRef} BlogRef={props.BlogRef}/>
			</nav>
		</div>
	</React.Fragment>

	);
};

export default sideDrawer;