import React, {useEffect} from 'react';

import styles from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import phone from '../../../assets/phone.svg'
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import {
    Flex,
    Box,
    Button,
    Text,
    Image,
    Link
} from 'rebass';


const Toolbar = props => 
{
	
	return(
		<React.Fragment>
			<header className={styles.Toolbar} id="Header">
				<Logo atTop={props.atTop}/>
				<DrawerToggle atTop={props.atTop} clicked={props.toggled} ContactRef={props.ContactRef} NosotrosRef={props.NosotrosRef} ServiciosRef={props.ServiciosRef} BlogRef={props.BlogRef}/>
				<nav className={styles.DesktopOnly}>
					<NavigationItems serviciosSectionRef = {props.serviciosSectionRef} ContactRef={props.ContactRef} NosotrosRef={props.NosotrosRef} ServiciosRef={props.ServiciosRef} BlogRef={props.BlogRef} closed={props.closed} atTop={props.atTop}/>
				</nav>
			</header>
		</React.Fragment>
	);
}


export default Toolbar;