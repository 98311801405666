import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import './assets/fonts/MadeSonara/MADESONARA.otf'; 
import './assets/fonts/Raleway/Raleway-Bold.ttf'; 
import './assets/fonts/Raleway/Raleway-ExtraBold.ttf'; 
import './assets/fonts/Raleway/Raleway-Medium.ttf'; 
import './assets/fonts/Raleway/Raleway-Regular.ttf'; 

import App from './App';
import './index.css';


ReactDOM.render(
  <React.StrictMode>
	  <BrowserRouter>
	    <App />
	  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
