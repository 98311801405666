import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box,
  Button
} from 'rebass';
import styles from './Servicios.module.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Servicios = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Nosotros",

	 },
	 en: {
		title: "Nosotros",

	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [ beenHere, setBeenHere] = useState(false)


	return(
			<div className={styles.Container} id="Nosotros">
				<TrackVisibility partialVisibility>		
					{({ isVisible }) => {
						if(isVisible)
							setBeenHere(true)
						return(
						<React.Fragment>
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000}} transition={{ duration: 1 }} className={"MaxDimensions"} style={{position:"relative"}}>
								<Flex justifyContent={["flex-start"]} flexDirection="column" alignItems="flex-start" mb={100}>
								{props.accordion.map((a) => (
									<Accordion style={{width: "100%",
									maxWidth: "1000px",
									margin: "auto",
									marginBottom: "35px",
									minHeight: "130px",
									display: "flex",
									flexDirection:"column",
									justifyContent: "center",
									backgroundColor: "#F8F8F8"}}>
										<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
										style={{width: "100%",paddingLeft: 0}}
										>
										<Flex alignItems="center">
										<Flex backgroundColor="#223644" width="130px" height="130px" alignItems="center" justifyContent="center">
											<Image width="45px" height="45px" src={a.svg} />
										</Flex>
										<Flex marginLeft="40px">
											<Text textAlign="left" sx={{fontSize:["18px","25px"], color:"#223644", fontWeight:"700"}}>{a.title}</Text>
										</Flex>
										</Flex>
										</AccordionSummary>
										<AccordionDetails>
										<Typography className="ParentLi">
											{a.content}
										</Typography>
										</AccordionDetails>
									</Accordion>
								))}
								
								</Flex>
							</motion.div>
						</React.Fragment>
					)}}	
				</TrackVisibility>
			</div>
	);
}

export default Servicios;

