import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../../assets/sendArrowColor.svg'
import styles from './Proyectos.module.css';
import proyectos1 from "../../../assets/sections/blog/blog1.png"
import proyectos2 from "../../../assets/sections/blog/blog2.png"
import proyectos3 from "../../../assets/sections/blog/blog3.png"
import proyectos4 from "../../../assets/sections/blog/blog4.png"
import { motion } from "framer-motion"
import TrackVisibility from 'react-on-screen';


const Proyectos = (props) => {
	const history = useHistory();
	const [isVisible,setIsVisible] = useState(false)
	const [ beenHere, setBeenHere] = useState(false)

	let trans = new LocalizedStrings({
	 es:{
		title: "BLOG",
		subtitle: "Buscamos siempre superar las expectativas de nuestros clientes, mejorando continuamente nuestro desempeño.",
		seeEveryProject: "Ver todos los proyectos"
	 },
	 en: {
		title: "Pojects",
		subtitle: "We always seek to exceed the expectations of our customers, continuously improving our performance.",
		seeEveryProject: "See all projects" 
	 }
	});

	// const handleScroll = e => {
	// 	let DomHeight = props.refsTo.Proyectos.current.offsetTop - 874
	// 	let DomMaxHeight = props.refsTo.Clientes.current.offsetTop
	// 	if(window.innerWidth >= 700 && window.innerWidth <= 1099){
	// 		DomHeight = props.refsTo.Proyectos.current.offsetTop - 989
	// 		DomMaxHeight = props.refsTo.Clientes.current.offsetTop - 989
	// 	}
	// 	else if(window.innerWidth >= 0 && window.innerWidth <= 699){
	// 		DomHeight = props.refsTo.Proyectos.current.offsetTop - 640
	// 		DomMaxHeight = props.refsTo.Clientes.current.offsetTop - 640
	// 	}
	// 	if (window.scrollY >= DomHeight && window.scrollY < DomMaxHeight) {
	// 		setIsVisible(true);
	// 	}
	// }

	// useEffect(() => {
	// 	window.addEventListener('scroll', handleScroll);
	// 	return function cleanup() {
	// 		window.removeEventListener('scroll', handleScroll);
	// 	}
	// },[]);

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);


	return(
			<div className={styles.Container} id="Proyectos">
				<TrackVisibility partialVisibility>		
					{({ isVisible }) => {
						if(isVisible)
							setBeenHere(true)
						return(
			<div className={"MaxDimensions"} style={{position:"relative"}}>
				<Flex flexDirection={["column"]} >
					<Flex flexDirection={["column","column","row"]} mb={[0,0,90]} alignItems={["unset","unset","center"]}>
						<motion.div className={styles.AnimatedFromTop} initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000}} transition={{ duration: 1 }}>
								<Text
									fontWeight="100" 
									textAlign="left" 
									fontFamily="MadeSonara" 
									fontSize={[45,70,70]} 
									color="#2E333E">{trans.title}</Text>
						</motion.div>
					</Flex>
					<Flex flexDirection={["column"]}>
						<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000}} transition={{ duration: 1 }}>
							<Flex flexDirection={["column","row"]} justifyContent={["space-between"]} mb={[0,"20px"]}>
								<Box width={['calc(100%)',"50%"]} mr={["0","1%"]} ml={["0","0"]}>
									<Box className="ZoomInContainer" mb={["20px",0]} height={[299]} width={['100%']}>
										<Box   className="ZoomIn" sx={{cursor:"pointer",position:"relative",backgroundImage:[`url(${proyectos1})`],backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}} >
										</Box>
									</Box>
									<Box mb={[50,0]} mt={[0,"20px"]} textAlign="left">
										<Text fontSize={[16]}>12/5/2019</Text>
										<Text fontSize={[18]} fontWeight="700">Lorem Ipsum Dolor</Text>
										<Text fontSize={[18]}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</Text>
									</Box>
								</Box>
								<Box width={['calc(100%)',"50%"]} ml={["0","1%"]} mr={["0","0"]}>
									<Box className="ZoomInContainer" mb={["20px",0]} height={[299]} width={['100%']}>	
										<Box    className="ZoomIn" sx={{cursor:"pointer",position:"relative",backgroundImage:[`url(${proyectos2})`],backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}} >
										</Box>
									</Box>
									<Box mb={[50,0]} mt={[0,"20px"]} textAlign="left">
										<Text fontSize={[16]}>12/5/2019</Text>
										<Text fontSize={[18]} fontWeight="700">Lorem Ipsum Dolor</Text>
										<Text fontSize={[18]}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</Text>
									</Box>
								</Box>
							</Flex>
						</motion.div>
						<motion.div style={{zIndex:10}} initial={{ opacity: 0, y: 1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, y: (isVisible || beenHere) ? 0 : 1000}} transition={{ duration: 1 }}>
							<Flex flexDirection={["column","row"]} justifyContent={["space-between"]}>

								<Box width={['calc(100%)',"50%"]} ml={["0","0"]} mr={["0","1%"]}>
									<Box className="ZoomInContainer" mb={["20px",0]} height={[299]} width={['100%']}>
										<Box   className="ZoomIn" sx={{cursor:"pointer",position:"relative",backgroundImage:[`url(${proyectos3})`],backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}} >
										</Box>
									</Box>
									<Box mb={[50,0]} mt={[0,"20px"]} textAlign="left">
										<Text fontSize={[16]}>12/5/2019</Text>
										<Text fontSize={[18]} fontWeight="700">Lorem Ipsum Dolor</Text>
										<Text fontSize={[18]}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</Text>
									</Box>
								</Box>

								<Box width={['calc(100%)',"50%"]}  ml={["0","1%"]} mr={["0","0"]}>
									<Box className="ZoomInContainer" mb={["20px",0]} height={[299]} width={['100%']}>
											
										<Box   className="ZoomIn" sx={{cursor:"pointer",position:"relative",backgroundImage:[`url(${proyectos4})`],backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}} >
										</Box>
									</Box>
									<Box mb={[50,0]} mt={[0,"20px"]} textAlign="left">
										<Text fontSize={[16]}>12/5/2019</Text>
										<Text fontSize={[18]} fontWeight="700">Lorem Ipsum Dolor</Text>
										<Text fontSize={[18]}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</Text>
									</Box>
								</Box>
							</Flex>
						</motion.div>
					</Flex>
				</Flex>
			</div>
			)}}
			</TrackVisibility>
			</div>
	);
}

export default Proyectos;