import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion"

import styles from './SectionTemplate.module.css';


const SectionTemplate = ({ isVisible, title, subtitle, sectionImg, order, maxHeightSection }) => {
    const [beenHere, setBeenHere] = useState(false)

    useEffect(() => {
        if (isVisible) {
            setBeenHere(true);
        }
    }, [isVisible])


    return (
        <div className={styles.container} id="SectionTemplate">
            <motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000 }} transition={{ duration: 1 }} className={"MaxDimensions"} >
                <div className={styles.sectionContainer} style={{maxHeight: `${maxHeightSection}`}}>
                    <div style={{ order: `${order === "1" ? 1 : 2}` , backgroundColor:`${order === "1" ? '#F0F0F0' : '#fff'}` }} className={styles.textContainer}>
                        <span className={styles.sectionTitle}>{title}</span>
                        <p className={styles.sectionSubtitle}>
                            {subtitle}
                        </p>
                    </div>
                    <div style={{ order: `${order === "1" ? 2 : 1}`}} className={styles.imgContainer}>
                        <img style={{objectFit:"cover", width:"100%", maxHeight:"100%"}} src={sectionImg} alt="" />
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default SectionTemplate
