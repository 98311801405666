import React, { useState } from 'react'

import styles from './Exportaciones.module.css';
import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"
import { useHistory } from 'react-router';


const Exportaciones = () => {
    const history = useHistory();
    const [beenHere, setBeenHere] = useState(false)

    return (
        <div className={styles.container}>
            <TrackVisibility partialVisibility>
                {({ isVisible }) => {
                    if (isVisible)
                        setBeenHere(true)
                    return (
                        <React.Fragment>
                            <motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000 }} transition={{ duration: 1 }} className={"MaxDimensions"} style={{ position: "relative" }}>
                                <div className={styles.exportContent} >
                                    <div className={styles.textContent}>
                                        <div className={styles.titleContainer}>
                                            <span className={styles.contentTitle}>¿NECESITAS AYUDA CON EXPORTACIONES & IMPORTACIONES?</span>
                                        </div>
                                        <div className={styles.buttonContainer}>
                                            <button onClick={() => history.push("/exportaciones")} className={styles.Button}>VER SERVICIOS</button>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </React.Fragment>
                    )
                }}

            </TrackVisibility>
        </div>
    )
}

export default Exportaciones
