import React, { useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { useHistory, NavLink } from 'react-router-dom';
import styles from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem'

import servicios2 from "../../../assets/svg/calculator.svg"
import servicios3 from "../../../assets/svg/document.svg"
import servicios4 from "../../../assets/svg/hospital.svg"
import servicios5 from "../../../assets/svg/elder.svg"
import arrowdown from "../../../assets/svg/arrowdown.svg"
import arrowup from "../../../assets/svg/arrowup.svg"

import nosotrosIcon from "../../../assets/navIconWe.png"
import blogIcon from "../../../assets/svg/navIconBlog.svg"
import contactoIcon from "../../../assets/svg/navIconContact.svg"
import serviciosIcon from "../../../assets/svg/navIconServices.svg"
import contaIcon from "../../../assets/svg/navDropDownIconConta.svg"
import imssIcon from "../../../assets/svg/navDropdownIconImss.svg"
import nominaIcon from "../../../assets/svg/navDropDownIconNom.svg"
import pensionesIcon from "../../../assets/svg/navDropDownIconPens.svg"


import { FullscreenModal } from "../../Modal/FullscreenModal"
import LangSelect from '../../LangSelect/LangSelect'
import Modal from 'react-modal';
import {
	Flex,
	Text,
	Image,
	Box
} from 'rebass';
const NavigationItems = (props) => {
	const { openSideDrawer } = props;
	const history = useHistory();
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [modal2IsOpen, setIsOpen2] = React.useState(false);

	const [dropedDown, setDropedDown] = React.useState(false);
	const openModal = () => {
		setIsOpen(true);
	}

	useEffect(() => {
		if (!openSideDrawer) {
			setDropedDown(false);
		}
	}, [openSideDrawer])

	const closeModal = () => {
		setIsOpen(false);
	}

	let trans = new LocalizedStrings({
		es: {
			Nosotros: "NOSOTROS",
			Servicios: "SERVICIOS",
			Inicio: "BLOG",
			Contacto: "CONTACTO",
		},
		en: {
			Inicio: "Home",
			Nosotros: "About us",
			Servicios: "Services",
			Contacto: "Contact",
		}
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const navigationClick = (nav) => {
		switch (nav) {
			case "Nosotros":
				// if(props.NosotrosRef)
				// 	props.NosotrosRef();
				// else
				history.push("/nosotros")

				props.closed();
				break;
			case "Servicios":
				if (openSideDrawer) {
					setDropedDown(!dropedDown);
				}
				// props.ServiciosRef();
				if (window.innerWidth <= 1100){
					//props.closed();
					//setDropedDown(dropedDown)
				}
				// 	setDropedDown(!dropedDown)
				// else {
				// 	console.log("HERE! else")
				// 	// openModal()
				// }
				break;
			case "Blog":
				// if (props.BlogRef)
				// 	props.BlogRef();
				// else
				history.push("/")
				props.closed();
				break;
			case "Contacto":
				history.push("/contacto");
				props.closed();
				break;
			default:
				props.closed();
				break;

		}


	}

	return (		
		<>
			<ul className={styles.NavigationItems}>
				<li className={`${openSideDrawer ? styles.NavigationItemMobile : styles.NavigationItem}`} onClick={() => navigationClick("Nosotros")} style={{ position: "relative" }} >
					<NavLink
						// className={(props.atTop && !window.innerWidth < 1100) ? styles.NavItem : styles.NavItem2}
						className={`${openSideDrawer ? styles.NavItemMobile : styles.NavItem}`}
						exact
						// activeClassName={styles.active} 
						to={`/nosotros`}
						activeClassName={styles.activeLink}
					>
						{openSideDrawer && <img width="29px" src={nosotrosIcon} alt="" />}
						<span style={{ marginLeft: "22px" }}>
							{trans.Nosotros}
						</span>
					</NavLink>
				</li>

				<li
					className={`${openSideDrawer ? styles.NavigationItemMobile : styles.NavigationItem} ${dropedDown ? styles.resetService : ''}`}
					onClick={() => navigationClick("Servicios")}
					onMouseEnter={() => { setIsOpen2(true) }}
					onMouseLeave={() => { setIsOpen2(false) }}
				>
					<div className={`${openSideDrawer ? styles.NavItemMobile : styles.NavItem}`} style={{ width: "100%", justifyContent: `${openSideDrawer && "space-between"}` }}>
						<div style={{ display: "flex", alignItems: `${openSideDrawer && "center"}` }}>
							{openSideDrawer && <img src={serviciosIcon} alt="" />}
							
							{
							(window.location.pathname =='/' 
								? <a
									onClick={props.serviciosSectionRef}
									style={{textDecoration:"none", color:"#C9B069"}}
									activeClassName={styles.activeLink}>
									<span style={{ marginLeft: "22px", fontWeight: `${(openSideDrawer && dropedDown ? "bold" : "100")}`, textDecoration: `${(openSideDrawer && dropedDown ? "underline" : "none")}` }}>
										{trans.Servicios}
									</span>
								</a> 
								:
								<NavLink
									style={{textDecoration:"none", color:"#C9B069"}}
									to="/servicios/"
									activeClassName={styles.activeLink}>
									<span style={{ marginLeft: "22px", fontWeight: `${(openSideDrawer && dropedDown ? "bold" : "100")}`, textDecoration: `${(openSideDrawer && dropedDown ? "underline" : "none")}` }}>
										{trans.Servicios}
									</span>
								</NavLink>
							)}
						</div>
						{
							(openSideDrawer &&
								(dropedDown ? <img src={arrowup} /> : <img src={arrowdown} />)

							)}
						{

							!openSideDrawer &&

							<div className={styles.desktopMenuDropdown} style={{ display: `${modal2IsOpen ? 'block' : 'none'}` }}>
								<div className={styles.containerDesktopMenuDropdown}>
									<div className={styles.desktopMenuDropdownItem} onClick={() => history.push("/servicios/contabilidad")}>
										<div className={styles.desktopMenuDropdownItemImgContainer}>
											<img src={servicios2} alt="" />
										</div>
										<div className={styles.desktopMenuDropdownItemTextContainer}>
											<span className={styles.desktopMenuDropdownItemText}>Contabilidad</span>
										</div>
									</div>
									<div className={styles.desktopMenuDropdownItem} onClick={() => history.push("/servicios/nomina")}>
										<div className={styles.desktopMenuDropdownItemImgContainer}>
											<img src={servicios3} alt="" />
										</div>
										<div className={styles.desktopMenuDropdownItemTextContainer}>
											<span className={styles.desktopMenuDropdownItemText}>Nómina</span>
										</div>
									</div>
									<div className={styles.desktopMenuDropdownItem} onClick={() => history.push("/servicios/imss")}>
										<div className={styles.desktopMenuDropdownItemImgContainer}>
											<img src={servicios4} alt="" />
										</div>
										<div className={styles.desktopMenuDropdownItemTextContainer}>
											<span className={styles.desktopMenuDropdownItemText}>IMSS</span>
										</div>
									</div>
									<div className={styles.desktopMenuDropdownItem} onClick={() => history.push("/servicios/pensiones")} >
										<div className={styles.desktopMenuDropdownItemImgContainer}>
											<img src={servicios5} alt="" />
										</div>
										<div className={styles.desktopMenuDropdownItemTextContainer}>
											<span className={styles.desktopMenuDropdownItemText}>Pensiones</span>
										</div>
									</div>
								</div>


							</div>

						}
					</div>

				</li>


				{/** -------------------------- DROPDOWN MENU SERVICES ------------------------------  */}
				<li style={{ display: dropedDown ? "block" : "none", width: "100%" }}>
					<ul className={styles.NavigationItems}>
						<li onClick={() => history.push("/servicios/contabilidad")} className={styles.NavItemDropDownContainer} >
							<a className={styles.NavItemDropDown}>
								<div style={{ display: "flex", width: "30px", justifyContent: "center" }}>
									<img src={contaIcon} alt="" />
								</div>
								<span style={{ marginLeft: "24px", width: "90%", textAlign: "start" }}> Contabilidad</span>
							</a>
						</li>
						<li onClick={() => history.push("/servicios/nomina")} className={styles.NavItemDropDownContainer}>
							<a className={styles.NavItemDropDown}>
								<div style={{ display: "flex", width: "30px", justifyContent: "center" }}>
									<img src={nominaIcon} alt="" />
								</div>
								<span style={{ marginLeft: "24px", width: "90%", textAlign: "start" }}> Nomina</span>
							</a>
						</li>
						<li onClick={() => history.push("/servicios/imss")} className={styles.NavItemDropDownContainer} >
							<a className={styles.NavItemDropDown}>
								<div style={{ display: "flex", width: "30px", justifyContent: "center" }}>
									<img src={imssIcon} alt="" />
								</div>
								<span style={{ marginLeft: "24px", width: "90%", textAlign: "start" }}> IMSS</span>
							</a>
						</li>
						<li onClick={() => history.push("/servicios/pensiones")} className={styles.NavItemDropDownContainer} >
							<a className={styles.NavItemDropDown}>
								<div style={{ display: "flex", width: "30px", justifyContent: "center" }}>
									<img src={pensionesIcon} alt="" />
								</div>
								<span style={{ marginLeft: "24px", width: "90%", textAlign: "start" }}> Pensiones</span>
							</a>
						</li>
					</ul>
				</li>


				{/*<li className={`${openSideDrawer ? styles.NavigationItemMobile : styles.NavigationItem}`} onClick={() => navigationClick("Blog")}>
					<NavLink
						className={`${openSideDrawer ? styles.NavItemMobile : styles.NavItem}`}
						exact
						to={`/${urlLang}`}>
						{openSideDrawer && <img src={blogIcon} alt="" />}
						<span style={{ marginLeft: "22px" }}>
							BLOG
						</span>
					</NavLink>
				</li>*/}
				<li className={`${openSideDrawer ? styles.NavigationItemMobile : styles.NavigationItem}`} onClick={() => navigationClick("Contacto")}>
					<NavLink
						className={`${openSideDrawer ? styles.NavItemMobile : styles.NavItem}`}
						exact
						to={`/contacto`}
						activeClassName={styles.activeLink}
					>
						{openSideDrawer && <img src={contactoIcon} alt="" />}
						<span style={{ marginLeft: "22px" }}>
							{trans.Contacto}
						</span>
					</NavLink>
				</li>
			</ul>

			{/* ----------------------------- MODAL SERVICES DESKTOP ONLY -------------------------- */}
			<Modal
				isOpen={false}

				//   onAfterOpen={afterOpenModal}

				onRequestClose={closeModal}
				style={{
					content: {
						inset: "auto",
						// top: '80px !important',
						// left: '50%',
						// right: "20px !important",
						height: "340px",
						width: "1000px"
						// bottom: 'auto',
						// marginRight: '-50%',
						// transform: 'translate(-50%, -50%)'
					}
				}}
				contentLabel="Example Modal"
			>
				<Flex flexWrap="wrap" height="100%" onMouseLeave={() => closeModal()}>

					<Flex flexDirection="column" justifyContent="center" className={styles.hoverStyles} alignItems="center" onClick={() => history.push("/servicios/contabilidad")} width="25%" sx={{ cursor: "pointer" }}>
						<Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" mt={20} mb={20} sx={{ borderRight: "1px solid #00000029", cursor: "pointer" }}>
							<Flex flex="1" alignItems="flex-end" justifyContent="center" pb={18}>
								<Image sx={{ filter: "brightness(0)" }} src={servicios2} />
							</Flex>
							<Flex marginTop="20px" flexDirection="column" flex="1" alignItems="center" justifyContent="flex-start">
								<Text color="#5D656F" fontSize={[18]} className={styles.hoverbolder} fontWeight="300">Contabilidad</Text>
							</Flex>
						</Flex>
					</Flex>
					<Flex flexDirection="column" justifyContent="center" className={styles.hoverStyles} alignItems="center" onClick={() => history.push("/servicios/nomina")} width="25%" sx={{ cursor: "pointer" }}>
						<Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" mt={20} mb={20} sx={{ borderRight: "1px solid #00000029", cursor: "pointer" }}>
							<Flex flex="1" alignItems="flex-end" justifyContent="center" pb={18}>
								<Image sx={{ filter: "brightness(0)" }} src={servicios3} />
							</Flex>
							<Flex marginTop="20px" flexDirection="column" flex="1" alignItems="center" justifyContent="flex-start">
								<Text color="#5D656F" fontSize={[18]} className={styles.hoverbolder} fontWeight="300">Nómina</Text>
							</Flex>
						</Flex>
					</Flex>
					<Flex flexDirection="column" justifyContent="center" className={styles.hoverStyles} alignItems="center" onClick={() => history.push("/servicios/imss")} width="25%" sx={{ cursor: "pointer" }}>
						<Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" mt={20} mb={20} sx={{ borderRight: "1px solid #00000029", cursor: "pointer" }}>
							<Flex flex="1" alignItems="flex-end" justifyContent="center" pb={18}>
								<Image sx={{ filter: "brightness(0)" }} src={servicios4} />
							</Flex>
							<Flex marginTop="20px" flexDirection="column" flex="1" alignItems="center" justifyContent="flex-start">
								<Text color="#5D656F" fontSize={[18]} className={styles.hoverbolder} fontWeight="300">IMSS</Text>
							</Flex>
						</Flex>
					</Flex>
					<Flex flexDirection="column" justifyContent="center" className={styles.hoverStyles} alignItems="center" onClick={() => history.push("/servicios/pensiones")} width="25%" sx={{ cursor: "pointer" }}>
						<Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" mt={20} mb={20} sx={{ cursor: "pointer" }}>
							<Flex flex="1" alignItems="flex-end" justifyContent="center" pb={18}>
								<Image sx={{ filter: "brightness(0)" }} src={servicios5} />
							</Flex>
							<Flex marginTop="20px" flexDirection="column" flex="1" alignItems="center" justifyContent="flex-start">
								<Text color="#5D656F" fontSize={[18]} className={styles.hoverbolder} fontWeight="300">Pensiones</Text>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
}

export default NavigationItems;