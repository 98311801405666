import React, { useRef,useEffect } from 'react';

import styles from './Servicios.module.css'

import Layout from '../../components/Layout/Layout';
import Footer from '../../sections/Home/Footer/Footer';
import Title from '../../sections/Servicios/Title';
import Callapsables from '../../sections/Servicios/Callapsables';
import Contacto from '../../sections/Home/Contacto/Contacto';
import cover from '../../assets/sections/services/pension/pensiones.png';
import { Fade } from "react-awesome-reveal"
import svg1 from '../../assets/sections/services/pension/sbgpensiones/Grupo 143.svg';
import svg2 from '../../assets/sections/services/pension/sbgpensiones/Grupo 145.svg';
import svg3 from '../../assets/sections/services/pension/sbgpensiones/Grupo 147.svg';
import svg4 from '../../assets/sections/services/pension/sbgpensiones/Grupo 296.svg';
const Home = (props) => {

	const ContactoRef = useRef(null)


	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70) ,behavior: 'smooth',})
	}

	const accordion = [
		{title: "TRÁMITE DE PENSIÓN", svg: svg1, content: <ul> 
			<li>Asesoría personalizada</li>
			<li>Pensión por cesantía, pensión mínima garantizada</li>
			<li>Citas y acompañamiento</li>
			<li>Revisión de papelería</li>
			<li>Diagnóstico de historial laboral</li>
			<li>Correcciones de datos</li>
			<li>Cálculo de pensión</li>
			<li>Vigencia de derechos</li>
			<li>Pensión por viudez, invalidez, incapacidad, orfandad</li>
		</ul>}, 
		{title: "NEGATIVA DE PENSIÓN", svg: svg2, content: <ul> 
			<li>Recuperación de recursos</li>
			<li>Citas y acompañamiento</li>
			<li>Correcciones de datos</li>
			<li>SAR 92-97</li>
			<li>Subcuenta infonavit 92-97</li>
		</ul>},
		{title: "MODALIDAD 40", svg: svg3, content: <ul> 
			<li>Inscripción modalidad 40 para ley 73</li>
			<li>Salario topado</li>
			<li>Semanas cotizadas</li>
			<li>Cálculo de pensión</li>
		</ul>},
		   
		{title: "AFORE", svg: svg4, content: <ul> 
			<li>Inscripción modalidad 40 para ley 73</li>
			<li>Salario topado</li>
			<li>Semanas cotizadas</li>
			<li>Cálculo de pensión</li>
			<li>Retiro por desempleo</li>
		</ul>}	
	]
	
	
	return(
		<Fade delay={200}>
			<Layout scrollTo={{
							Contacto:toBottomContact

							}}>
				<div className={styles.Nosotros}>
						<div id="Nosotros1"   >
							<Title title="Pension" cover={cover}/>
						</div>

						<div id="Nosotros2"  >
							<Callapsables title="Pension" accordion={accordion}/>
						</div>

						<div id="Contacto" ref={ContactoRef}>
							<Contacto />
						</div>
						
						<div id="Footer">
							<Footer />
						</div>
				</div>
			</Layout>	
		</Fade>
	)	
};


export default Home;