import React, {useState} from 'react';
import {
    Flex,
    Box,
    Button,
    Text,
    Image,
} from 'rebass';
import {Select} from '@rebass/forms';
import language_white from '../../assets/language_white.svg';
import language from '../../assets/language.svg';
import "./LangSelect.css"
import LocalizedStrings from 'react-localization';

const LangSelect = (props) => {

	const lang = window.location.pathname.split("/")[1];
	
	const [Lang,setLang] = useState(lang);

	const handleChange = (e) => {
		setLang(e.target.value);
		if(e.target.value == "es" && lang != "es"){
			window.location.href = window.location.pathname.replace("/en","/es")
		}
		else if(e.target.value == "en" && lang != "en"){
			window.location.href = window.location.pathname.replace("/es","/en")
		}
	}

	const icon_sx = props.icon_sx?props.icon_sx:{position:"absolute",left:8,top:16}

	let content = (
				<Box sx={{position:"relative"}} display="flex">
					<Image src={language_white} sx={icon_sx}/>
					<Select onChange={handleChange} defaultValue={Lang} pl="36px" width="130px" height="47px" color="white" className=" SelectLanguage ">
				        <option style={{color:"black"}} value="en" >
				                 English
				        </option>
				        <option style={{color:"black"}} value="es" >
				                 Español
				        </option>
			        </Select>
		        </Box>
		        )
	if(props.HomeHeaderWhite){
		content = <Box sx={{position:"relative",width:"fit-content",margin:"auto"}} display={["flex", "flex", "flex"]} className="language_white">
				    <Image src={language_white} sx={icon_sx}/>
					<Select onChange={handleChange} sx={{border:"none"}} defaultValue={Lang} fontSize={[18,24,18]} pl="36px" width={[130,150,130]} height="47px" color="white" className=" SelectLanguage ">
				        <option style={{color:"black"}} value="en" >
				                 English
				        </option>
				        <option style={{color:"black"}} value="es" >
				                 Español
				        </option>
			        </Select>
				</Box>
	}
	else if(props.HomeHeaderBlack){
		content = <Box sx={{position:"relative",width:"fit-content",margin:"auto"}} className="language"> 
				    <Image src={language} sx={icon_sx}/>
					<Select onChange={handleChange} sx={{border:"none"}} defaultValue={Lang} fontSize={[18,24,18]} pl="36px" width={[130,150,130]} height="47px" color="black" className=" SelectLanguageB ">
				        <option style={{color:"black"}} value="en" >
				                 English
				        </option>
				        <option style={{color:"black"}} value="es" >
				                 Español
				        </option>
			        </Select>
				</Box>
	}
	else if(props.HomeHeaderMobile){
		content = <Box sx={{position:"relative"}}> 
				    <Image src={language} sx={icon_sx}/>
					<Select onChange={handleChange} sx={{border:"none"}} defaultValue={Lang} pl="36px" width="130px" height="47px" color="black" className=" SelectLanguageB ">
				        <option style={{color:"black"}} value="en" >
				                 English
				        </option>
				        <option style={{color:"black"}} value="es" >
				                 Español
				        </option>
			        </Select>
				</Box>
	}
	else if(props.HomeHeaderIndividual){
		content = <Box sx={{position:"relative"}} display={["none", "flex", "flex"]}> 
				    <Image src={language}  sx={icon_sx}/>
					<Select onChange={handleChange} sx={{border:"none"}} defaultValue={Lang} pl="36px" width="130px" height="47px" color="black" className=" SelectLanguageB ">
				        <option style={{color:"black"}} value="en" >
				                 English
				        </option>
				        <option style={{color:"black"}} value="es" >
				                 Español
				        </option>
			        </Select>
				</Box>
	}

	return(
		content
	);

}

export default LangSelect;