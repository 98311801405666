import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
	Flex,
	Text,
	Image,
	Box,
	Button
} from 'rebass';
import send from '../../../assets/sendArrow2.svg'
import styles from './Servicios.module.css';

import services1 from "../../../assets/sections/services/services1.png"
import services2 from "../../../assets/sections/services/services2.png"
import services3 from "../../../assets/sections/services/services3.png"
import services4 from "../../../assets/sections/services/services4.png"


import services5 from "../../../assets/sections/services/services5.png"
import services6 from "../../../assets/sections/services/services6.png"
import services7 from "../../../assets/sections/services/services7.png"
import services8 from "../../../assets/sections/services/services8.png"


import servicios2 from "../../../assets/svg/calculator.svg"
import servicios3 from "../../../assets/svg/document.svg"
import servicios4 from "../../../assets/svg/hospital.svg"
import servicios5 from "../../../assets/svg/elder.svg"



import arrowDown from "../../../assets/arrowDown.svg"

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Servicios = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
		es: {
			title: "SERVICIOS",
			boxTitle1_1: "Plomería y Electricidad",
			boxTitle1_2: "en Construcción",
			boxTitle2_1: "Diseño, construcción y",
			boxTitle2_2: "mantenimiento de",
			boxTitle2_3: "piscinas, spa, fuentes etc.",
			boxTitle3_1: "Mantenimiento e",
			boxTitle3_2: "Instalaciones de",
			boxTitle3_3: "Plomería y Electricidad",
			boxTitle4_1: "Venta de equipos de Bombeo,",
			boxTitle4_2: "Calentadores y Filtrado",
			boxTitle4_3: "para servicio de Piscinas",
			boxTitle5_1: "Distribución de túneles",
			boxTitle5_2: "sanitizantes",
			seeMore: "Ver todos los Servicios"
		},
		en: {
			title: "Services",
			boxTitle1_1: "Plumbing and Electricity",
			boxTitle1_2: "in Construction",
			boxTitle2_1: "Design, construction and",
			boxTitle2_2: "maintenance of",
			boxTitle2_3: "swimming pools, spas, fountains, etc.",
			boxTitle3_1: "Maintenance and",
			boxTitle3_2: "Installations of",
			boxTitle3_3: "Plumbing and Electricity",
			boxTitle4_1: "Sale of pumping equipment,",
			boxTitle4_2: "heaters and filtration",
			boxTitle4_3: "for swimming pool service",
			boxTitle5_1: "Distribution of sanitation",
			boxTitle5_2: "tunnels",
			seeMore: "See all the Services"
		}
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [beenHere, setBeenHere] = useState(false)





	return (
		<div className={styles.Container} id="Servicios">
			<TrackVisibility partialVisibility>
				{({ isVisible }) => {
					if (isVisible)
						setBeenHere(true)
					return (
						<React.Fragment>
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000 }} transition={{ duration: 1 }} className={"MaxDimensions"} style={{ position: "relative" }}>
								<Flex justifyContent={["flex-start", "space-between", "space-between"]} alignItems="center" mb={[60, 90, 90]}>
									<Text
										fontWeight="100"
										letterSpacing={[0,"0px","0px"]}
										fontFamily="MadeSonara"
										// sx={{textShadow:"#00000052 0 9px 6px"}} 
										fontSize={[45, 70, 70]}
										lineHeight={["54px","84px","84px"]}
										color="#2E333E">{trans.title}</Text>
								</Flex>
							</motion.div>
							<motion.div initial={{ opacity: 0, y: 300 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, y: (isVisible || beenHere) ? 0 : 300 }} transition={{ duration: 1 }} className={"MaxDimensions"} style={{ position: "relative" }}>
								<Flex justifyContent="center" alignItems="center">
									<Flex flexDirection={["row", "row", "row"]} flexWrap="wrap" width="100%" height="100%" >
										<Flex flexWrap="wrap" flexDirection={["row", "row", "column"]} width={["100%", "100%", "calc(50% - 2px)"]} mr={[0, 0, "2px"]} ml={[0, 0, 0]}>
											<Box onClick={() => history.push("/servicios/contabilidad")} mb="4px" mr={[0, "2px", 0]} backgroundColor="#373E50" width={["100%", "calc(50% - 2px)", "100%"]} height={["251px","410px","410px"]} className="ZoomInContainer">
												<Flex flexDirection="column" height="calc(100% - 30px)" width="calc(100% - 30px)"  className="ZoomSibling" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", zIndex: 1, border: "1px solid #C9B069" }}>
													<Flex flex={["unset","1"] } height={["50%","auto"]} alignItems="flex-end" justifyContent="center" pb={18} >
														<Image width={["34px","45px"]}  className={styles.imgIcon}  src={servicios2} />
													</Flex>
													<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="flex-start">
														<Text color="#C9B069" fontSize={[22,30,30]} fontWeight="700">CONTABILIDAD</Text>
														<Flex sx={{ border: "1px solid #C9B069", width: "123px", height: "42px", marginTop: ["20px","27px"], justifyContent: "center", alignItems: "center" }}>
															<Text color="#C9B069" fontSize={[18]}>VER MÁS</Text>
														</Flex>
													</Flex>
												</Flex>
												<Flex className="ZoomIn" sx={{ background: `linear-gradient(0deg, rgba(34, 54,68, 0.8), rgba(34, 54, 68, 0.8)), url(${services8})`, backgroundSize: "cover", cursor: "pointer" }} flexDirection="column" alignItems="center" justifyContent="center" >
												</Flex>
											</Box>
											<Box onClick={() => history.push("/servicios/imss")} className="ZoomInContainer" mb={["4px", "4px", 0]} ml={[0, "2px", 0]} backgroundColor="#373E50" width={["100%", "calc(50% - 2px)", "100%"]} height={["251px","410px","410px"]}>
												<Flex flexDirection="column" height="calc(100% - 30px)" width="calc(100% - 30px)" className="ZoomSibling" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", zIndex: 1, border: "1px solid #C9B069" }}>
													<Flex flex={["unset","1"] } height={["50%","auto"]} alignItems="flex-end" justifyContent="center" pb={18}>
														<Image width={["45px","48px"]}   className={styles.imgIcon} src={servicios4} />
													</Flex>
													<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="flex-start">
														<Text color="#C9B069"  fontSize={[22,30,30]}fontWeight="700">IMSS</Text>
														<Flex sx={{ border: "1px solid #C9B069", width: "123px", height: "42px", marginTop: ["20px","27px"], justifyContent: "center", alignItems: "center" }}>
															<Text color="#C9B069" fontSize={[18]}>VER MÁS</Text>
														</Flex>
													</Flex>
												</Flex>
												<Flex className="ZoomIn" sx={{ background: `linear-gradient(0deg, rgba(34, 54,68, 0.8), rgba(34, 54, 68, 0.8)), url(${services6})`, backgroundSize: "cover", cursor: "pointer" }} flexDirection="column" alignItems="center" justifyContent="center" >
												</Flex>
											</Box>
										</Flex>

										<Flex flexWrap="wrap" flexDirection={["row", "row", "column"]} width={["100%", "100%", "calc(50% - 2px)"]} ml={[0, 0, "2px"]}>
											<Box onClick={() => history.push("/servicios/nomina")} className="ZoomInContainer" mb="4px" mr={[0, "2px", 0]} backgroundColor="#373E50" width={["100%", "calc(50% - 2px)", "100%"]} height={["251px","410px","410px"]}>
												<Flex flexDirection="column" height="calc(100% - 30px)" width="calc(100% - 30px)" className="ZoomSibling" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", zIndex: 1, border: "1px solid #C9B069" }}>
													<Flex flex={["unset","1"] } height={["50%","auto"]} alignItems="flex-end" justifyContent="center" pb={18}>
														<Image width={["48px","71px"]} className={styles.imgIcon} src={servicios3} />
													</Flex>
													<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="flex-start">
														<Text color="#C9B069"  fontSize={[22,30,30]} fontWeight="700">NÓMINA</Text>
														<Flex sx={{ border: "1px solid #C9B069", width: "123px", height: "42px", marginTop: ["20px","27px"], justifyContent: "center", alignItems: "center" }}>
															<Text color="#C9B069" fontSize={[18]}>VER MÁS</Text>
														</Flex>
													</Flex>
												</Flex>
												<Flex className="ZoomIn" sx={{ background: `linear-gradient(0deg, rgba(34, 54,68, 0.8), rgba(34, 54, 68, 0.8)), url(${services7})`, backgroundSize: "cover", cursor: "pointer" }} flexDirection="column" alignItems="center" justifyContent="center" >
												</Flex>
											</Box>
											<Box onClick={() => history.push("/servicios/pensiones")} className="ZoomInContainer" mb={["4px", "4px", 0]} ml={[0, "2px", 0]} backgroundColor="#373E50" width={["100%", "calc(50% - 2px)", "100%"]} height={["251px","410px","410px"]}>
												<Flex flexDirection="column" height="calc(100% - 30px)" width="calc(100% - 30px)" className="ZoomSibling" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", zIndex: 1, border: "1px solid #C9B069" }}>
													<Flex flex={["unset","1"] }  height={["50%","auto"]} alignItems="flex-end" justifyContent="center" pb={18}>
														<Image width={["25px","36px"]}  className={styles.imgIcon} src={servicios5} />
													</Flex>
													<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="flex-start">
														<Text color="#C9B069"  fontSize={[22,30,30]}  fontWeight="700">PENSIONES Y AFORES</Text>
														<Flex sx={{ border: "1px solid #C9B069", width: "123px", height: "42px", marginTop: ["20px","27px"], justifyContent: "center", alignItems: "center" }}>
															<Text color="#C9B069" fontSize={[18]}>VER MÁS</Text>
														</Flex>
													</Flex>
												</Flex>
												<Flex className="ZoomIn" sx={{ background: `linear-gradient(0deg, rgba(34, 54,68, 0.8), rgba(34, 54, 68, 0.8)), url(${services5})`, backgroundSize: "cover", cursor: "pointer" }} flexDirection="column" alignItems="center" justifyContent="center" >
												</Flex>
											</Box>

										</Flex>
									</Flex>
								</Flex>
							</motion.div>
						</React.Fragment>
					)
				}}
			</TrackVisibility>
		</div>
	);
}

export default Servicios;

