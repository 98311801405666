import React, { useRef } from 'react'
import Layout from '../../components/Layout/Layout'
import Contacto from '../../sections/Home/Contacto/Contacto'
import Footer from '../../sections/Home/Footer/Footer'

import styles from './Exportaciones.module.css'
import { Fade } from "react-awesome-reveal"
import SectionTemplate from '../../components/UI/SectionTemplate/SectionTemplate'
import TrackVisibility from 'react-on-screen'


import exportacion1 from '../../assets/sections/export/exportacion1.png';
import exportacion2 from '../../assets/sections/export/exportacion2.png';
import exportacion3 from '../../assets/sections/export/exportacion3.png';
import exportacion4 from '../../assets/sections/export/exportacion4.png';
import exportacion5 from '../../assets/sections/export/exportacion5.png';

const Home = () => {
    const ContactoRef = useRef(null)


    const toBottomContact = () => {
        window.scrollTo({ top: (ContactoRef.current.offsetTop - 70), behavior: 'smooth', })
    }

    return (
        <Fade delay={200}>
            <Layout scrollTo={{
                Contacto: toBottomContact

            }}>
                <div className={styles.container}>
                    <div className={styles.coverMainContainer}>
                        <div className={styles.coverContainer}>
                            <div className={styles.coverTitleContainer}>
                                <span className={styles.coverTitle}>EXPORTACIONES, IMPORTACIONES</span>
                            </div>
                            <div className={styles.coverSubtitleContainer}>

                                <div className={styles.coverSubtitle}>
                                    <span className={styles.subtitleSpan}>
                                        También nos especializamos en Importaciones y Exportaciones en las áreas de:
                                    </span>
                                    <ul className={styles.subtitleList}>
                                        <li>· Comercialización de productos</li>
                                        <li>· Consultoria en comercio exterior</li>
                                        <li>· Planes de negocio</li>
                                        <li>· Asesoría</li>
                                        <li>· Gestoría</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <TrackVisibility partialVisibility>
                        <SectionTemplate
                            title="PROVEEDORES"
                            subtitle="Contamos con una amplia lista de proveedores en AMERICA, ASIA, EUROPA y RESTO DEL MUNDO, en todas las áreas y sectores, ofreciendo productos de primera calidad y seriedad en los cierres de negocios."
                            sectionImg={exportacion1}
                            order="1"
                            maxHeightSection="626px"
                        />
                    </TrackVisibility>
                    <TrackVisibility partialVisibility>
                        <SectionTemplate
                            title="LOGÍSTICA"
                            subtitle="Brindamos servicio de Recepción, Distribución y Almacenamiento. Facilitamos los procesos de transportación y gestión de entregas, además de abatir costos para que nuestros clientes cuenten con una mejor ganancia, y puedan ser más competitivos en el mercado."
                            sectionImg={exportacion3}
                            order="2"
                            maxHeightSection="626px"
                        />
                    </TrackVisibility>
                    <TrackVisibility partialVisibility>
                        <SectionTemplate
                            title="TRANSPORTE"
                            subtitle="Apoyamos y asesoramos a pequeñas empresas para iniciar con proyectos de importación, de igual manera contamos con permisos requeridos por las dependencias mexicanas para cada sector empresarial, para importación de mercancías y programas otorgados por la Secretaria de Economía (IMEX, SIIXE)."
                            sectionImg={exportacion4}
                            order="1"
                            maxHeightSection="626px"
                        />
                    </TrackVisibility>
                    <TrackVisibility partialVisibility>
                        <SectionTemplate
                            title="TRÁMITES"
                            subtitle="Contamos con costos bajos en transportación: Aérea, Terrestre y Marítima, con la más novedosa línea de transportes terrestres que emplean localizadores (GPS) para monitorear el trayecto de su mercancía, así como resguardos y seguridad privada para sus traslados. Ofrecemos contratación de fletes marítimos con contenedores 20´40´, frigoríficos y líneas aéreas de primer mundo."
                            sectionImg={exportacion5}
                            order="2"
                            maxHeightSection="626px"
                        />
                    </TrackVisibility>

                    <div id="Contacto" ref={ContactoRef}>
                        <Contacto />
                    </div>

                    <div id="Footer">
                        <Footer />
                    </div>
                </div>
            </Layout>
        </Fade>
    )
}

export default Home
