import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import styles from './Contacto.module.css';
import LocalizedStrings from 'react-localization';
import { useHistory } from 'react-router-dom';

import Input from '../../../components/UI/Input/Input';
import * as util from './util';

import {
	Flex,
	Text,
	Image,
	Box
} from 'rebass';

import isotipo from '../../../assets/svg/logo.svg'
import mobile from '../../../assets/svg/android.svg'
import email from '../../../assets/svg/email.svg'
import phone from '../../../assets/svg/phone.svg'
import location from '../../../assets/svg/location.svg'

import facebook from '../../../assets/svg/facebook.svg'
import insta from '../../../assets/svg/insta.svg'
import twitter from '../../../assets/svg/twitter.svg'




const Contacto = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
		es: {
			text: "Contáctanos",
			name: "Nombre",
			message: "Mensaje",
			send: "Enviar",
			title: "Enviado",
			textSwal: "Nos pondremos en contacto contigo",

		}
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);


	return (
		<div className={styles.Container} id="Contacto">
			<div className={"MaxDimensions"}>
				<Box>
					<Flex flexDirection={["column","column","row"]} >
						<Box width={["100%", "100%", "25%"]}>
							<Flex flexDirection="column" alignItems={["center", "center", "flex-start"]}  marginBottom={[80, 80, 0]}>
								<Image width={[105, 151]} height="auto" src={isotipo} alt="" />
							</Flex>
						</Box>
						<Box width={["100%", "100%", "75%"]}>
							<Flex justifyContent={["space-between"]} flexDirection={["column", "row", "row"]}>
								<Flex flexDirection="column" alignItems="flex-start" width={["100%", "33%", "33%"]} marginBottom={[70, 70, 0]}>
									<Text sx={{ cursor: "pointer" }} onClick={() => { history.push("/nosotros") }} marginBottom={[43, 43, 24]} className={styles.imgText}>NOSOTROS</Text>
									<Text sx={{ cursor: "pointer" }} onClick={() => {
										if (props.scrollTo)
											props.scrollTo.Servicios()
										else
											history.push("/")
									}} marginBottom={[43, 43, 24]} className={styles.imgText}>SERVICIOS</Text>
									<Text sx={{ cursor: "pointer" }} onClick={() => {
										if (props.scrollTo)
											props.scrollTo.Blog()
										else
											history.push("/")
									}} marginBottom={[43, 43, 24]} className={styles.imgText}>BLOG</Text>
									<Text sx={{ cursor: "pointer" }} onClick={() => { history.push("/contacto") }} marginBottom={[43, 43, 24]} className={styles.imgText}>CONTACTO</Text>
								</Flex>
								<Flex flexDirection="column" alignItems="flex-start" width={["100%", "33%", "33%"]} marginBottom={[80, 80, 0]}>
									<div className={styles.imgBox}>
										<div style={{ width: 35, marginRight: 10, boxSizing: "border-box" }}>
											<img src={email} alt="" className={styles.imgIcon} />
										</div>
										<div className={styles.imgText}><a href="mailto:contacto@tgo.com.mx?body=Hi, I want more information">contacto@tgo.com.mx</a></div>
									</div>
									<div className={styles.imgBox}>
										<div style={{ width: 35, marginRight: 10, boxSizing: "border-box" }}>
											<img src={mobile} alt="" className={styles.imgIcon} />
										</div>
										<div className={styles.imgText}>Cel.-<a href="tel:+52-33-3369-1787">33 3369 1787</a></div>
									</div>
									<div className={styles.imgBox}>
										<div style={{ width: 35, marginRight: 10, boxSizing: "border-box" }}>
											<img src={phone} alt="" className={styles.imgIcon} />
										</div>
										<div className={styles.imgText}>Oficina.- <a href="tel:+52-33-2016-2398">33 2016 2398</a></div>
									</div>
								</Flex>
								<Flex className={styles.locationContainer} flexDirection="row" alignItems="flex-start" width={["100%", "38%", "38%"]}>
									<Image src={location} width="35px" marginTop="7px" marginRight="9px" /><Text className={styles.locationText} color="#C9B069" textAlign={["justify", "left", "left"]} letterSpacing="0.8px" lineHeight="28px" ><a href="https://maps.app.goo.gl/Q6zMhqdJWFn3uttA9" target="_blank">Abogados Consultores TGO <br/> Mar Blanco 1592, Lomas del Country, 44620 Guadalajara, Jal.</a></Text>

								</Flex>
							</Flex>
						</Box>
					</Flex>
					<Flex justifyContent="center" alignItems="center" paddingTop="90px">
						<Flex width="285px" justifyContent="space-around">
							<Image src={facebook} />
							<Image src={insta} />
							<Image src={twitter} />
						</Flex>
					</Flex>
				</Box>
			</div>
		</div>
	);
}

export default Contacto;