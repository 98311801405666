import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
	Flex,
	Text,
	Image,
	Box,
	Button
} from 'rebass';
import styles from './Nosotros2.module.css';
import nosotros2 from "../../../assets/sections/about/about2.png"
import nosotros3 from "../../../assets/sections/about/about3.png"
import about1 from "../../../assets/svg/about1.svg"
import about2 from "../../../assets/svg/about2.svg"

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Servicios = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
		es: {
			title: "Nosotros",

		},
		en: {
			title: "Nosotros",

		}
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [beenHere, setBeenHere] = useState(false)


	return (
		<div className={styles.Container} id="Nosotros">
			<TrackVisibility partialVisibility>
				{({ isVisible }) => {
					if (isVisible)
						setBeenHere(true)
					return (
						<React.Fragment>
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000 }} transition={{ duration: 1 }} className={"MaxDimensions"} style={{ position: "relative", maxWidth: "1200px !important" }}>
								<Flex className={styles.containerVisionMision} paddingRight={[15,0,0]}  flexDirection={["column", "column", "row"]} mb={[50, 130]}>
									<Flex  flex="1" justifyContent={["flex-start", "center", "flex-start"]} flexDirection="column" alignItems={["center", "center", "flex-start"]} marginTop={[0, 0, 0]} paddingLeft={[15, 0, 0]} paddingRight={[15,0,0]} mb={[70, 70, 0]}>
										<Box width="100%" display="flex" marginBottom="30px" justifyContent={["flex-start", "flex-start", "flex-start"]}>
											<Image height="auto" sx={{ zIndex: 2, objectFit: "cover" }} src={about1} />
											<Text
												className={styles.misionText}
												fontWeight="900"
												marginLeft="16px"
												// sx={{textShadow:"#00000052 0 9px 6px"}} 
												fontSize={[20, 24]}
												color="#2E333E">NUESTRA MISIÓN</Text>
										</Box>
										<Box width="100%"  display="flex" justifyContent={["flex-start", "flex-start", "flex-start"]}>
											<Text
												fontWeight="300"
												textAlign={["left", "left"]}
												// sx={{textShadow:"#00000052 0 9px 6px"}} 
												fontSize={[16, 18, 18]}
												maxWidth={["100%","100%",418]}
												lineHeight={["35px"]}
												color="#2E333E">Nuestro propósito es construir a los próximos pioneros para lograr su máxima eficiencia administrativa a través de nuestra línea de servicios integrados en materia Contable, Fiscal y Administrativa. Brindamos soluciones efectivas y guiando a nuestros clientes al logro de sus objetivos.</Text>
										</Box>
									</Flex>
									<Flex flex="1" flexDirection="column" alignItems={["flex-start", "center", "flex-start"]} justifyContent={["center", "center", "flex-start"]} paddingLeft={[15, 0, 0]} paddingRight={[15,0,0]} marginLeft={["0px","0px","40px"]}>
										<Box width="100%" display="flex" marginBottom="30px" justifyContent={["flex-start", "flex-start", "flex-start"]} >
											<Image height="auto" sx={{ zIndex: 2, objectFit: "contain" }} src={about2} />
											<Text
												fontWeight="900"
												marginLeft="16px"
												// sx={{textShadow:"#00000052 0 9px 6px"}} 
												fontSize={[20, 24]}
												color="#2E333E">NUESTRA VISIÓN</Text>
										</Box>
										<Box width="100%"  display="flex" justifyContent={["flex-start", "flex-start", "flex-start"]}>
											<Text
												
												fontWeight="300"
												textAlign={["left", "left"]}
												// sx={{textShadow:"#00000052 0 9px 6px"}} 
												maxWidth={["100%","100%",482]}
												fontSize={[16, 18, 18]}
												lineHeight={["35px"]}
												color="#2E333E">Consolidarnos como un despacho líder, con nuestro equipo multidisciplinario de alto rendimiento, incluyendo nuestros servicios de atención y mejora continua, para generar confianza en el mercado financiero que nos posicione en la mejor opción para tu empresa.</Text>
										</Box>
									</Flex>
								</Flex>
							</motion.div>
							<motion.div initial={{ opacity: 0, y: 300 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, y: (isVisible || beenHere) ? 0 : 300 }} transition={{ duration: 1 }} className={"MaxDimensions"} style={{ position: "relative" }}>
								<Flex flexDirection={["column", "column", "column"]} justifyContent={["flex-start"]} paddingLeft={[0]} alignItems={["center", "center", "flex-start"]} mb={[0, 90, 90]}>
									<Flex width={["100%", "100%", "100%"]} justifyContent={["flex-start", "center", "center"]} mt={[0, 50, 0]} mb={[60, 60, 0]} flexDirection="column" alignItems={["flex-start", "center", "center"]}  >
										<Box width="100%"  display="flex" margin={["auto"]} marginBottom="40px" justifyContent={["flex-start", "center", "center"]} paddingLeft={[15, 0, 0]} paddingRight={[15,0,0]} >
											<Image height="auto" sx={{ zIndex: 2, objectFit: "contain", width: 31 }} src={nosotros3} />
											<Text
												fontWeight="900"
												marginLeft="16px"
												textAlign="center"
												// sx={{textShadow:"#00000052 0 9px 6px"}} 
												fontSize={[20, 24]}
												color="#2E333E">VALORES</Text>
										</Box>
										<Box width="100%">
											<Box display="flex">
												<ul className={styles.ulParent}>
													<li>
														<Text
															fontWeight="300"
															maxWidth={500}
															textAlign={["center", "left"]}
															// sx={{textShadow:"#00000052 0 9px 6px"}} 
															fontSize={[16, 18, 18]}
															color="#2E333E">Trabajo en equipo</Text>
													</li>
													<li>
														<Text
															fontWeight="300"
															maxWidth={500}
															textAlign={["center", "left"]}
															// sx={{textShadow:"#00000052 0 9px 6px"}} 
															fontSize={[16, 18, 18]}
															color="#2E333E">Responsabilidad</Text>
													</li>
													<li>
														<Text
															fontWeight="300"
															maxWidth={500}
															textAlign={["center", "left"]}
															// sx={{textShadow:"#00000052 0 9px 6px"}} 
															fontSize={[16, 18, 18]}
															color="#2E333E">Transparencia</Text>
													</li>
													<li>
														<Text
															fontWeight="300"
															maxWidth={500}
															textAlign={["center", "left"]}
															// sx={{textShadow:"#00000052 0 9px 6px"}} 
															fontSize={[16, 18, 18]}
															color="#2E333E">Compromiso</Text>
													</li>
													<li>
														<Text
															fontWeight="300"
															maxWidth={500}
															textAlign={["center", "left"]}
															// sx={{textShadow:"#00000052 0 9px 6px"}} 
															fontSize={[16, 18, 18]}
															color="#2E333E">Solidaridad</Text>
													</li>
													<li>
														<Text
															fontWeight="300"
															maxWidth={500}
															textAlign={["center", "left"]}
															// sx={{textShadow:"#00000052 0 9px 6px"}} 
															fontSize={[16, 18, 18]}
															color="#2E333E">Disciplina</Text>
													</li>
													<li>
														<Text
															fontWeight="300"
															maxWidth={500}
															textAlign={["center", "left"]}
															// sx={{textShadow:"#00000052 0 9px 6px"}} 
															fontSize={[16, 18, 18]}
															color="#2E333E">Honestidad</Text>
													</li>
													<li>
														<Text
															fontWeight="300"
															maxWidth={500}
															textAlign={["center", "left"]}
															// sx={{textShadow:"#00000052 0 9px 6px"}} 
															fontSize={[16, 18, 18]}
															color="#2E333E">Puntualidad</Text>
													</li>
												</ul>
											</Box>
										</Box>
									</Flex>
								</Flex>
							</motion.div>
							<div id="NosotrosContainer__3">
								<motion.div initial={{ opacity: 0, y: 300 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, y: (isVisible || beenHere) ? 0 : 300 }} transition={{ duration: 1 }} className={"MaxDimensions"} style={{ position: "relative", maxWidth: "1200px !important" }}>
									<Flex id="caja3" flexDirection={["column", "row", "row"]} justifyContent="space-between" className={styles.containerSection}>
										<Box maxHeight="454px" id="caja2" width={["100%", "100%", "50%"]} display={"flex"} justifyContent={["center", "center", "flex-end"]} sx={{ position: "relative" }}>
											<div className={styles.overlayImg}></div>
											<img style={{ zIndex: 2, objectFit: "cover", width: "100%", height: "100%" }} src={nosotros2} />
										</Box>
										<Box id="caja1" width="50%" width={["100%", "100%", "50%"]}
											sx={{ backgroundColor: "#F0F0F0", padding: ["81px 15px 82px 15px ", "40px, 30px 40px 30px", "165px 134px 109px 140px "] }}
											order={[2, 2, 0]} justifyContent={["center", "center", "flex-start"]}>
											<Text
												fontWeight="100"
												maxWidth={["100%","100%","401px"]}
												// paddingTop="50px"
												mb={[0, 0, 0]}
												sx={{ lineHeight: "26px" }}
												letterSpacing="0.8px"
												textAlign={["center", "center", "left"]}
												// sx={{textShadow:"#00000052 0 9px 6px"}} 
												fontSize={[16, 16, 16]}
												color="#223644">Si necesitas que el servicio se realice en tus instalaciones contamos con técnicos contables, auxiliares contables, contador general y contador fiscal, para que la papelería no salga de tu negocio.</Text>
										</Box>

									</Flex>
								</motion.div>
							</div>
						</React.Fragment>
					)
				}}
			</TrackVisibility>
		</div>
	);
}

export default Servicios;

